import Network from '@/services/network';

export default function handleTdsVds() {
    const network = new Network;

    const fetchTdsVdsRates = (query) => {
        return network.api('get', '/procurement/tds-vds-rate/' + query);
    }

    const fetchTdsRates = (query) => {
        return network.api('get', '/procurement/tds-rate/' + query);
    }

    const fetchVdsRates = (query) => {
        return network.api('get', '/procurement/vds-rate/' + query);
    }

    return {
        fetchTdsVdsRates,
        fetchTdsRates,
        fetchVdsRates
    }
}